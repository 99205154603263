export { default as Menu } from './menu.svg'
// export { default as RedNodeAnimation } from './images/Red Node.mp4'
// export { default as GoldNodeAnimation } from './images/Gold Node.mp4'
// export { default as GreenNodeAnimation } from './images/Green Node.mp4'
// export { default as BlueNodeAnimation } from './images/Blue Node.mp4'
// export { default as IridescenceNodeAnimation } from './images/Iridescence Node.mp4'

export { default as HeroBackground } from './images/hero_bg.svg'
export { default as DojoLogoTextWhite } from './images/dojo_logo_text_white.svg'
export { default as HeroMobileBackground } from './images/hero_mobile_bg.svg'

// export { default as GoldNodeAnimation } from './images/Dojo/MP4/Node GOLD Large.mp4'
// export { default as GreenNodeAnimation } from './images/Dojo/MP4/Node Green Large.mp4'
// export { default as BlueNodeAnimation } from './images/Dojo/MP4/Node Purple Large.mp4'
// export { default as IridescenceNodeAnimation } from './images/Dojo/MP4/Node Holo Large.mp4'
// export { default as RedNodeAnimation } from './images/Dojo/MP4/Node Red Large.mp4'

// export { default as GoldNodeAnimation } from './images/Dojo/WebM/Medium/Node GOLD WM_QC_Medium.webm'
// export { default as GreenNodeAnimation } from './images/Dojo/WebM/Medium/Node Green WM_QC_Medium.webm'
// export { default as BlueNodeAnimation } from './images/Dojo/WebM/Medium/Node Purple WM_QC_Medium.webm'
// export { default as RedNodeAnimation } from './images/Dojo/WebM/Medium/Node Red WM_QC_Medium.webm'
// export { default as IridescenceNodeAnimation } from './images/Dojo/WebM/Medium/Node Holo WM_TS_Medium.webm'

// export const GoldNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722349235/ejiahns263bndbdvrpsd.webm";
// export const GreenNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722348841/f7kw8rnylxisph2to7ju.webm"
// export const BlueNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722349638/yosibdaooywfvlgwyhtn.webm";
// export const RedNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722349700/hzzajmmagffmmaxegnfr.webm";
// export const IridescenceNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722349537/tqg0qmsoaxpyat0oafn7.webm";

export const GoldNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722602651/tfrqehyrloljo6voc6oy.mp4";
export const GreenNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722602718/dmfexydedz21tnl7ubzq.mp4"
export const BlueNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722602810/t4djlxcm8z53dcphjzxc.mp4";
export const RedNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722602868/fsexl1jaanjn7udx1qfm.mp4";
export const IridescenceNodeAnimation = "https://res.cloudinary.com/dj2piyh9y/video/upload/v1722602930/jumlxyoiv21ycsxuasx9.mp4";

// export { default as IridescenceNodeAnimation } from './images/Dojo/PNG/Node Holo NFT SME0.jpg'
// export { default as RedNodeAnimation } from './images/Dojo/PNG/Node Red NFT SME0.jpg'
// export { default as BlueNodeAnimation } from './images/Dojo/PNG/Node Purple NFT SME0.jpg'
// export { default as GreenNodeAnimation } from './images/Dojo/PNG/Node Green NFT SME0.jpg'
// export { default as GoldNodeAnimation } from './images/Dojo/PNG/Node GOLD NFT SME0.jpg'